const WHITELABEL_KEYS = require('./global-config-variables/whitelabel-keys');

module.exports = {
  CONFIG_SITE: {
    WHITELABEL_KEY: WHITELABEL_KEYS.xooply,
    DEFAULT_PHOTO: 'https://storage.googleapis.com/xooply-assets/default-photo.png',
    LOGO_XOOPLY_WITH_METRANET: 'https://storage.googleapis.com/xooply-assets/logo-xooply-by-metranet.png',
    LOGO_URL: 'https://storage.googleapis.com/xooply-assets/logo_MztI84tZQ.svg',
    LOGO_MOBILE_URL: 'https://storage.googleapis.com/xooply-assets/logo-mobile_TEefbcxSr.svg',
    ICO_URL: 'https://storage.googleapis.com/xooply-assets/xooply.ico',
    CATEGORY_POPOVER_IMAGE_URL:
      'https://storage.googleapis.com/xooply-assets/category-popover-image.jpg',
    PRODUCT_CARD_WATERMARK_URL: '',
    REVISED_WATERMARK_URL: 'https://storage.googleapis.com/xooply-assets/revised-watermark.png',
    DOCUMENT_LOGO_PATH: 'https://storage.googleapis.com/xooply-assets/logo_MztI84tZQ.svg',
    PAID_WATERMARK_IMAGE_URL: 'https://storage.googleapis.com/xooply-assets/paid-watermark-xooply.png',
    SELLER: {
      HIDE_PICKUP_COURIER: false,
      DASHBOARD_BANNER: {
        IS_SHOW: false,
        IMAGES: 'https://storage.googleapis.com/xooply-assets/idfood/main-banner-panduan-penjual.jpg',
        CALLBACK_URL: 'https://storage.googleapis.com/xooply-assets/idfood/documents/IDFOOD_Seller_Feature.pdf',
        IS_OPEN_NEW_TAB: true
      },
    },
    EMETERAI_PRODUCTID: '1105',
    APPLY_REVAMPED_TAX: true,
    APPLY_DECIMAL_HANDLER: true,
    MAX_BUYER_STAFF_AMOUNT: 5,
    PICKUP_AT_STORE: true,
    REWARD_PROGRAM: true,
    BANNER_MANAGEMENT: true,
    ARTICLES_MANAGEMENT: true,
    PRODUCT_COLLECTION_MANAGEMENT: true,
    AMPDD: true,
    ENABLE_OPEN_MARKET: true,
    CONTENT: {
      BRAND: 'Xooply',
      DOMAIN: 'Xooply.id',
      EXECUTIVE_PERSON: {
        NAME: 'Doddi Herlambang',
        POSITION: 'VP Xooply',
      },
      COMPANY: {
        NAME: 'PT. Metra-Net',
        BRAND: 'Xooply',
        DOMAIN: 'Xooply.id',
        WEBSITE: 'https://xooply.id',
      },
      PROCUREMENT: [
	'Azhari Syarifulloh'
      ],
      ADDRESS_LIST: [
        'PT. Metra-Net Jakarta',
        'Mulia Business Park , Building J',
        'Jln. MT Haryono Kav 58-60 Jakarta Selatan',
        '12780',
      ],
      DOCUMENT_ADDRESS_LIST: [
        'PT Metranet',
        'Jl Letjen MT Haryono Kav 58 - 60,',
        'Pancoran, Jakarta Selatan, 12780',
        'No. HP: (021) 79187260',
        'No. NPWP: 02.978.514.4-093.000',
      ],
      CONTACTS: {
        WHATSAPP: {
          NUMBER: '081119621812',
          LINK: 'https://wa.me/6281119621812',
        },
        TELEGRAM: {
          USERNAME: '@HD_Bussop',
          LINK: 'https://t.me/HD_Bussop',
        },
        EMAIL: {
          SUPPORT: 'corporate.support@metranet.co.id',
        },
	PHONE: {
	  NUMBER: '+6281119621812',
	},
	EMBED_GOOGLE_MAP: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.138395759749!2d106.84405215079683!3d-6.245486295457065!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f3b9001470bf%3A0x6ba8b244e28bc14!2sPT%20METRA-NET!5e0!3m2!1sen!2sid!4v1679718380790!5m2!1sen!2sid" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',
      },
      DOCUMENT_TERMS_AND_CONDITIONS_URL: 'https://storage.googleapis.com/xooply-assets/markdown-content/terms-and-conditions.md',
      LINKS: [],
      FOOTER: {
        SUMMARY: `Platform B2B e-commerce produk dari PT Metra-Net (Metranet). Platform yang mengusung tagline “Your Business Supply Solution” menyediakan kebutuhan bisnis seperti stationary, consumer goods, electronic, custom product dan berbagai produk-produk Maintenance, Repairing & Operation (MRO) serta layanan lainnya.`,
        JOINED_COMPANY_LOGO_URLS: [
          'https://storage.googleapis.com/xooply-assets-public/company/company-aken.png',
          'https://storage.googleapis.com/xooply-assets-public/company/company-idea.png',
          'https://storage.googleapis.com/xooply-assets-public/company/company-lkpp.png',
        ],
        SUPPORTED_PAYMENT_LOGO_URLS: [
          'https://storage.googleapis.com/xooply-assets-public/payment-bni.png',
          'https://storage.googleapis.com/xooply-assets-public/payment-bri.png',
          'https://storage.googleapis.com/xooply-assets-public/payment-mandiri.png',
          'https://storage.googleapis.com/xooply-assets-public/credit-card.jpg',
        ],
        SUPPORTED_LOGISTIC_LOGO_URLS: [
          'https://storage.googleapis.com/xooply-assets-public/courier-logo/delivery-jne.png',
          'https://storage.googleapis.com/xooply-assets-public/courier-logo/delivery-jnt.png',
          'https://storage.googleapis.com/xooply-assets-public/courier-logo/delivery-paxel.png',
          'https://storage.googleapis.com/xooply-assets-public/courier-logo/delivery-deliveree.png',
          'https://storage.googleapis.com/xooply-assets-public/courier-logo/delivery-gojek.png',
          'https://storage.googleapis.com/xooply-assets-public/courier-logo/delivery-grab.png',
        ],
	STANDARDS_AND_CERTIFICATIONS: [
          'https://storage.googleapis.com/xooply-assets/iso27001.png',
        ],
      },
      SOCIAL_MEDIA_LINKS: [
       {
         name: 'facebook',
         link: 'https://m.facebook.com/xooply',
         username: '',
       },
       {
         name: 'instagram',
         link: 'https://instagram.com/xooply.id?igshid=MzRlODBiNWFlZA==',
         username: '',
       },
       {
         name: 'linkedin',
         link: 'https://www.linkedin.com/company/xooply',
         username: '',
       },
       {
         name: 'tiktok',
         link: 'https://www.tiktok.com/@xooply.id?_t=8gZxtIZ3jHN&_r=1',
         username: '',
       },
       {
         name: 'twitterx',
         link: 'https://x.com/xooply_id?s=21&t=kWnyS2RjyhAHPUcsfkVDfQ',
         username: '',
       },
       {
         name: 'youtube',
         link: 'https://youtube.com/@xooply_id?si=m7nUCgHm7KNgEGAX',
         username: '',
       },
      ],
      PAGE: {
        MAINSITE: {
          HOMEPAGE: {
          popup_banner: {
              name: 'popup_banner',
              title: 'popup_banner',
              content: {
                image: {
                  path: 'https://storage.googleapis.com/xooply-assets/banner-homepage/popup-banner.png',
                },
                redirectUrl: '/',
                sortIndex: 1,
              },
            },
            hero_banner: {
              name: 'Main Banners',
              title: 'Main Banners',
              content: [
                {
                  image: {
                    path: 'https://storage.googleapis.com/xooply-assets/banner-homepage-samsung.png',
                    seo: {
                      alt: 'pre-order Samsung Galaxy S24',
                      desc: 'Pendaftaran pre-order Galaxy S24 kini telah dibuka. Dapatkan beragam kecanggihan fitur yang tersedia dari smartphone Samsung Galaxy S24. Pre-order sekarang!',
                    },
                  },
                  redirectUrl: '/cp/preorder-samsung-galaxy-s24',
                  sortIndex: 0,
                },
                {
                  image: {
                    path: 'https://storage.googleapis.com/xooply-assets/hero-banner-1_H0BTm_ca3.jpg',
                    seo: {
                      alt: 'Xooply your business solution',
                      desc: 'Xooply your business solution',
                    },
                  },
                  redirectUrl: '/cp/hot-product',
                  sortIndex: 1,
                },
                {
                  type: 'LARGE',
                  image: {
                    path: 'https://storage.googleapis.com/xooply-assets/hero-banner-register.jpg',
                    seo: {
                      alt: 'bergabung xooply dan temukan semua kemudahan berbelanja',
                      desc: 'bergabung xooply dan temukan semua kemudahan berbelanja',
                    },
                  },
                  redirectUrl: '/register',
                  sortIndex: 2,
                },
              ],
            },
            recommendation_banner: {
              name: 'recommendation banners',
              title: 'Kebutuhan Bisnis Anda',
              content: [
                {
                  image: {
                    path: 'https://storage.googleapis.com/download/storage/v1/b/xooply-static-production/o/dev%2Fmini-banner_2.webp-XnrvBy.webp?generation=1731918452281123&alt=media',
                    seo: {
                      alt: 'Cara cepat beli e-Meterai',
                      desc: 'Cara cepat beli e-Meterai',
                    },
                  },
                  redirectUrl: '/cp/emeterai',
                  sortIndex: 0,
                },
                {
                  image: {
                    path: 'https://storage.googleapis.com/download/storage/v1/b/xooply-static-production/o/dev%2Fmini-banner_3.webp-m1XjNs.webp?generation=1731918561760680&alt=media',
                    seo: {
                      alt: 'Bubuhkan e-Meterai gak pakai lama',
                      desc: 'Bubuhkan e-Meterai gak pakai lama',
                    },
                  },
                  redirectUrl: '/cp/emeterai',
                  sortIndex: 1,
                },
                {
                  image: {
                    path: 'https://storage.googleapis.com/download/storage/v1/b/xooply-static-production/o/dev%2FMiniBanner-E-meterai.webp-gnZ6M1.webp?generation=1731918737220276&alt=media',
                    seo: {
                      alt: 'Mudah daftar CPNS/PPPK pakai e-Meterai',
                      desc: 'Mudah daftar CPNS/PPPK pakai e-Meterai',
                    },
                  },
                  redirectUrl: '/cp/emeterai',
                  sortIndex: 2,
                },
                {
                  image: {
                    path: 'https://storage.googleapis.com/download/storage/v1/b/xooply-static-production/o/dev%2Fe-materai-mini%20(1)%20(1).webp-FWEGN0.webp?generation=1731905450676100&alt=media',
                    seo: {
                      alt: 'Praktis beli e-Meterai resmi di Xooply',
                      desc: 'Praktis beli e-Meterai resmi di Xooply',
                    },
                  },
                  redirectUrl: '/cp/emeterai',
                  sortIndex: 3,
                },
              ],
            },
            middle_banner: {
              name: 'middle_banner',
              title: 'middle_banner',
              content: {
                image: {
                  path: 'https://storage.googleapis.com/xooply-assets/middle-banner-rfq.jpg',
                  seo: {
                    alt: 'Xooply your business solution',
                    desc: 'Xooply your business solution',
                  },
                },
                redirectUrl: '/cp/rfq',
                sortIndex: 1,
              },
            },
            highlighted_products: [
              {
                name: 'Office Supplies',
                title: 'Office Supplies',
                categoryId: '1',
		categoryCode: 'alat-tulis-kantor',
                content: {
                  redirectUrl: null,
                  banner: {
                    image: {
                      path: 'https://storage.googleapis.com/xooply-assets/highlighted-product-kantor.jpg',
                      seo: {
                        alt: 'Groceries',
                        desc: 'Groceries',
                      },
                    },
                    redirectUrl: '/',
                    sortIndex: 2,
                  },
                  products: [],
                },
              },
              {
                name: 'Komputer & Elektronik',
                title: 'Komputer & Elektronik',
                categoryId: '4',
		categoryCode: 'peralatan-elektronik',
                content: {
                  redirectUrl: '/',
                  banner: {
                    image: {
                      path: 'https://storage.googleapis.com/xooply-assets/highlighted-product-gadget.jpg',
                      seo: {
                        alt: 'Komputer & Elektronik',
                        desc: 'Komputer & Elektronik',
                      },
                    },
                    redirectUrl: '/',
                    sortIndex: 1,
                  },
                  products: [],
                },
              },
            ],
            buyer_testimonial: [
              {
                name: 'Azalika Andjani',
                occupation: 'Karyawan Swasta',
                rate: 4.5,
                review:
                  'Xooply memberikan pengalaman berbelanja yang seamless, karena UI dan UX mudah dipahami.',
              },
              {
                name: 'Fatharany Aqni',
                occupation: 'Karyawan Swasta',
                rate: 4.5,
                review:
                  'Desain antarmuka yang sederhana dan kemudahan navigasi menurutku harus tetap dipertahankan.',
              },
              {
                name: 'Renata Adelhia Setiawan',
                occupation: 'Karyawan Swasta',
                rate: 4.5,
                review:
                  'Xooply menyediakan fitur yang memudahkan user untuk melakukan transaksi dan pembelian.',
              },
              {
                name: 'Alisya Shiva Itjerenni',
                occupation: 'Karyawan Swasta',
                rate: 4,
                review: 'Xooply mudah digunakan dan diaplikasikan bahkan untuk pengguna baru.',
              },
              {
                name: 'Friska Arifiani',
                occupation: 'Karyawan Swasta',
                rate: 4,
                review:
                  'Terdapat notifikasi web sehingga informasi didalamnya mengenai status transaksi sangat jelas.',
              },
              {
                name: 'Muhamad Nabil Ihsani',
                occupation: 'Karyawan Swasta',
                rate: 4,
                review:
                  'Status transaksi cukup mudah dipahami karena terdapat semua detail status pesanan yang dibutuhkan.',
              },
              {
                name: 'Marsya Ilina',
                occupation: 'Karyawan Swasta',
                rate: 4.5,
                review:
                  'Saya sangat puas bertransaksi di xooply karena mudah dalam pengaplikasiannya dan kategori produk jelas dapat dimengerti.',
              },
              {
                name: 'Nabila Tiara Putri',
                occupation: 'Karyawan Swasta',
                rate: 5,
                review:
                  'Menurut saya platform xooply memberikan tampilan yang memudahkan transaksi.',
              },
              {
                name: 'Art Fabiozie',
                occupation: 'Karyawan Swasta',
                rate: 5,
                review: 'Proses barang dikemas dan dikirim sangat cepat.',
              },
            ],
          },
	  COLLECTION_PAGE: {
            HOT_PRODUCT: {
              hero_banner: {
                content: [
                  {
                    image: {
                      path: 'https://storage.googleapis.com/xooply-assets/cp/hot-product/hot-product-banner-1.jpg',
                    },
                    redirectUrl: '',
                    sortIndex: 0,
                  },
                ],
              },
              priceStockIds: [
                '1164',
                '486',
                '872',
              ],
              breadcrumb: {
                pages: [{ name: 'Koleksi Hot Product Terlengkap & Terbaik', href: '/cp/hot-product' }],
              },
            },
          },
        },
      },
    },
    MAINSITE: {
      HOMEPAGE_SEO_TEXT: true,
    },
    SELLER: {
      HIDE_PICKUP_COURIER: false,
      DASHBOARD_BANNER: {
        IS_SHOW: false,
        IMAGES: 'https://storage.googleapis.com/xooply-assets/idfood/main-banner-panduan-penjual.jpg',
        CALLBACK_URL: 'https://storage.googleapis.com/xooply-assets/idfood/documents/IDFOOD_Seller_Feature.pdf',
        IS_OPEN_NEW_TAB: true
      },
    },
    OPEN_GRAPH: {
      IMAGE: 'https://storage.googleapis.com/xooply-assets/og-xooply.png',
      TWITTER: {
        handle: '@xooply.id',
        site: '@xooply.id',
        cardType: 'summary_large_image',
      },
    },
    IS_SHOW: {
      REGISTER_RETAIL: false,
      TRACKING_ORDER: true,
      SERVICE_FEE: false,
      STORE_NAME_RETAIL: true,
    },
  },
  nextConfig: {
    publicRuntimeConfig: {},
    images: {
      domains: [
        'api.newxooply-dev.id',
        's.newxooply.id',
        's.newxooply-dev.id',
        'xooply-s-dev.codexpertindo.com',
        'xooply-api-dev.codexpertindo.com',
        'api.xooply.id',
        'static.xooply.id',
        'temp.xooply.id',
        'storage.googleapis.com',
      ],
    },
  },
  tailwindcss: {
    colorTheme: {
      primary: {
        main: '#E41B1B',
        lighter: '#EC5F5F',
        darker: '',
      },
      secondary: {
        main: '',
        lighter: '',
        darker: '',
      },
    },
  },
};
